import { Country, DataService } from '@vendure/admin-ui/core';
import { useInjector } from '@vendure/admin-ui/react';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { of, switchMap } from 'rxjs';

import SaveButton from '../../SaveButton/SaveButton';
import { usePrevious } from '../usePrevious';

import GeneralShippingAddress from './GeneralShippingAddress/GeneralShippingAddress';
import NovaPoshtaShippingAddress from './NovaPoshtaShippingAddress/NovaPoshtaShippingAddress';
import ShippingMethodSelect, {
  ShippingMethodWithHandlerCode,
} from './ShippingMethodSelect/ShippingMethodSelect';
import ShippingPrice from './ShippingPrice/ShippingPrice';

export type ShippingFormValues = {
  manualShippingPrice: string;
  shippingMethodId: string;
  countryCode: string;
  postalCode: string;
  province: string;
  city: string;
  streetLine1: string;
};

type Props = {
  orderId: string;
  shippingMethods?: ShippingMethodWithHandlerCode[];
  availableCountries?: Array<Partial<Country>>;
  defaultValues?: ShippingFormValues;
};

const ShippingForm: FC<Props> = ({
  shippingMethods,
  availableCountries,
  defaultValues,
  orderId,
}) => {
  const methods = useForm({ defaultValues });
  const dataService = useInjector(DataService);
  const [isLoading, setIsLoading] = useState(false);
  const shippingMethodId = useWatch({
    name: 'shippingMethodId',
    control: methods.control,
  });
  const previousShippingMethodId = usePrevious(shippingMethodId);

  useEffect(() => {
    if (shippingMethodId !== previousShippingMethodId) {
      const { resetField, setValue } = methods;
      if (methods.formState.dirtyFields.shippingMethodId) {
        setValue('postalCode', '');
        setValue('city', '');
        setValue('province', '');
        setValue('streetLine1', '');
        setValue('manualShippingPrice', '');
        setValue('countryCode', '');
      } else {
        resetField('postalCode');
        resetField('city');
        resetField('province');
        resetField('streetLine1');
        resetField('manualShippingPrice');
        resetField('countryCode');
      }
    }
  }, [methods, previousShippingMethodId, shippingMethodId]);

  const handleDataSubmit = useCallback(
    (data: any) => {
      const {
        manualShippingPrice,
        streetLine1,
        city,
        province,
        postalCode,
        countryCode,
      } = data;
      const selectedShippingMethod = shippingMethods?.find(
        (method) => method.id === data.shippingMethodId,
      );

      if (!selectedShippingMethod) return;

      setIsLoading(true);
      dataService.order
        .updateOrderCustomFields({
          id: orderId,
          customFields: {
            manualShippingPrice: manualShippingPrice
              ? Number(manualShippingPrice) * 100
              : null,
          },
        })
        .pipe(
          switchMap(() => {
            const setShippingMethod$ =
              dataService.order.setDraftOrderShippingMethod(
                orderId,
                data.shippingMethodId,
              );

            return setShippingMethod$.pipe(
              switchMap(() => {
                let setShippingAddress$;
                if (
                  selectedShippingMethod.fulfillmentHandlerCode ===
                  'nova-poshta-fulfillment'
                ) {
                  setShippingAddress$ =
                    dataService.order.setDraftOrderShippingAddress(orderId, {
                      streetLine1,
                      city,
                      countryCode: 'UA',
                    });
                } else {
                  setShippingAddress$ =
                    dataService.order.setDraftOrderShippingAddress(orderId, {
                      streetLine1,
                      city,
                      countryCode,
                      province,
                      postalCode,
                    });
                }

                return setShippingAddress$ ? setShippingAddress$ : of(null);
              }),
            );
          }),
        )
        .subscribe({
          complete: () => {
            const currentValues = methods.getValues();
            methods.reset(currentValues);
            setIsLoading(false);
          },
        });
    },
    [shippingMethods, dataService.order, orderId, methods],
  );

  const selectedShippingMethodCode = useMemo(
    () =>
      shippingMethods?.find((method) => method.id === shippingMethodId)?.code,
    [shippingMethods, shippingMethodId],
  );

  const { isDirty } = methods.formState;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleDataSubmit)}>
        <ShippingMethodSelect shippingMethods={shippingMethods} />
        <ShippingPrice shippingMethods={shippingMethods} />

        {selectedShippingMethodCode && (
          <div
            style={{
              marginTop: 20,
              borderTop:
                '1px solid var(--clr-table-border-color, hsl(198, 0%, 80%))',
            }}
          >
            {selectedShippingMethodCode === 'nova-poshta' ? (
              <NovaPoshtaShippingAddress />
            ) : (
              <GeneralShippingAddress availableCountries={availableCountries} />
            )}

            <SaveButton
              isDirty={isDirty}
              isLoading={isLoading}
              hasInitialValues={!!methods.formState.defaultValues?.streetLine1}
            />
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default ShippingForm;
